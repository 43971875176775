import * as Actions from './actions'
import ActionType from './action-type'
import {Api} from '../../../api/Api'
import {REST} from '../../../index'
import {ThunkAction} from 'redux-thunk'
import AppState from '../../types/app-state'
import {
    DEFAULT_INCIDENTS_REPORT_FILTER,
    DEFAULT_INCIDENTS_REPORT_FILTER_STATE,
    FilterExpandedType,
    IncidentsReportFilter,
} from './state'
import {SavedIncidentsReportFilter} from '../saved-filters/state'
import {formattedLocationsBlockSet, populateLocationsForFilterCriteria} from '../../../utils/Utils'
import {AnyAction, Dispatch} from 'redux'
import {setCurrentAppliedSavedFilter} from '../saved-filters/action-creators'
import {getFormattedIncidentsReportFilter} from '../../../components/saved-search/components/shared/helper'
import {LocationIdType, isActiveLocation} from '../locations/state'
import {AxiosResponse} from 'axios'
import {IncidentWidgetType} from '../../../pages/incidents-report/contexts/types/incident-widget-type'

const USER_PREFS_URL = '/api/v1/userPreferences/incidentsReport'

export const fetchIncidentsReportFilter = (
    isDefaultTagUser: boolean,
    savedFilters?: SavedIncidentsReportFilter[],
): ThunkAction<void, AppState, Api, Actions.Action> => {
    return (dispatch) => {
        dispatch(requestFilter())
        if (isDefaultTagUser === true) {
            REST.get(USER_PREFS_URL).then(
                (response) => {
                    REST.get(`${USER_PREFS_URL}.default_tag`).then(
                        (res) => {
                            if (response.data.value.selectedFilterName === undefined) {
                                dispatch(
                                    bulkAssignSavedFilter(
                                        {
                                            ...DEFAULT_INCIDENTS_REPORT_FILTER,
                                            locations: formattedLocationsBlockSet(
                                                response.data.value.locations,
                                            ),
                                            searchVesselTagTerm: res.data.value.default_tag ?? [],
                                            searchVesselNameTerm:
                                                response.data.value.searchVesselNameTerm ?? '',
                                        },
                                        'Saved filters',
                                        true,
                                    ),
                                )
                            } else {
                                const savedFilter =
                                    savedFilters &&
                                    savedFilters.find(
                                        (filter) =>
                                            filter.name === response.data.value.selectedFilterName,
                                    )

                                savedFilter
                                    ? dispatch(
                                          bulkAssignSavedFilter(
                                              {
                                                  ...getFormattedIncidentsReportFilter(
                                                      savedFilter.criteria as IncidentsReportFilter,
                                                  ),
                                                  locations: formattedLocationsBlockSet(
                                                      response.data.value.locations,
                                                  ),
                                                  searchVesselTagTerm: res.data.value.default_tag,
                                                  searchVesselNameTerm:
                                                      response.data.value.searchVesselNameTerm,
                                              },
                                              savedFilter.name,
                                              true,
                                              savedFilter,
                                          ),
                                      )
                                    : dispatch(
                                          bulkAssignSavedFilter(
                                              {
                                                  ...DEFAULT_INCIDENTS_REPORT_FILTER,
                                                  locations: formattedLocationsBlockSet(
                                                      response.data.value.locations,
                                                  ),
                                                  searchVesselTagTerm: res.data.value.default_tag,
                                                  searchVesselNameTerm:
                                                      response.data.value.searchVesselNameTerm,
                                              },
                                              'Saved filters',
                                              true,
                                          ),
                                      )
                            }
                            REST.delete(`${USER_PREFS_URL}.default_tag`)
                        },
                        () => {
                            dispatch(
                                bulkAssignSavedFilter(
                                    {
                                        ...DEFAULT_INCIDENTS_REPORT_FILTER,
                                        locations: formattedLocationsBlockSet(
                                            response.data.value.locations,
                                        ),
                                        searchVesselTagTerm:
                                            response.data.value.searchVesselTagTerm,
                                        searchVesselNameTerm:
                                            response.data.value.searchVesselNameTerm,
                                    },
                                    'Saved filters',
                                ),
                            )
                        },
                    )
                },
                () => {
                    REST.get(`${USER_PREFS_URL}.default_tag`).then((res) => {
                        REST.put(USER_PREFS_URL, {
                            ...DEFAULT_INCIDENTS_REPORT_FILTER,
                            searchVesselTagTerm: res.data.value.default_tag,
                        })
                        dispatch(
                            bulkAssignSavedFilter(
                                {
                                    ...DEFAULT_INCIDENTS_REPORT_FILTER,
                                    searchVesselTagTerm: res.data.value.default_tag,
                                },
                                'Saved filters',
                            ),
                        )
                    })
                },
            )
        } else {
            REST.get(USER_PREFS_URL).then(
                (response) => {
                    if (response.data.value.selectedFilterName === undefined) {
                        dispatch(
                            bulkAssignSavedFilter(
                                {
                                    ...DEFAULT_INCIDENTS_REPORT_FILTER,
                                    locations: formattedLocationsBlockSet(
                                        response.data.value.locations,
                                    ),
                                    searchVesselTagTerm:
                                        response.data.value.searchVesselTagTerm ?? [],
                                    searchVesselNameTerm:
                                        response.data.value.searchVesselNameTerm ?? '',
                                },
                                'Saved filters',
                            ),
                        )
                    } else {
                        const savedFilter =
                            savedFilters &&
                            savedFilters.find(
                                (filter) => filter.name === response.data.value.selectedFilterName,
                            )

                        savedFilter
                            ? dispatch(
                                  bulkAssignSavedFilter(
                                      {
                                          ...getFormattedIncidentsReportFilter(
                                              savedFilter.criteria as IncidentsReportFilter,
                                          ),
                                          locations: formattedLocationsBlockSet(
                                              response.data.value.locations,
                                          ),
                                          searchVesselTagTerm:
                                              response.data.value.searchVesselTagTerm,
                                          searchVesselNameTerm:
                                              response.data.value.searchVesselNameTerm,
                                      },
                                      savedFilter.name,
                                      false,
                                      savedFilter,
                                  ),
                              )
                            : dispatch(
                                  bulkAssignSavedFilter(
                                      {
                                          ...DEFAULT_INCIDENTS_REPORT_FILTER,
                                          locations: formattedLocationsBlockSet(
                                              response.data.value.locations,
                                          ),
                                          searchVesselTagTerm:
                                              response.data.value.searchVesselTagTerm,
                                          searchVesselNameTerm:
                                              response.data.value.searchVesselNameTerm,
                                      },
                                      'Saved filters',
                                  ),
                              )
                    }
                },
                () => {
                    dispatch(
                        bulkAssignSavedFilter(DEFAULT_INCIDENTS_REPORT_FILTER, 'Saved filters'),
                    )
                },
            )
        }
    }
}

function requestFilter(): Actions.RequestFilterAction {
    return {
        type: ActionType.REQUEST_FILTER,
    }
}

export function setDateFilter(
    fromDate: string | null,
    toDate: string | null,
): Actions.SetDateFilterAction {
    return {type: ActionType.SET_DATE_FILTER, payload: {fromDate, toDate}}
}

export function displayIncidentWidgetOnDashboard(
    widgetType: IncidentWidgetType,
): ThunkAction<void, AppState, Api, Actions.SetIncidentWidgetValueAction> {
    return (dispatch) => {
        dispatch({
            type: ActionType.SET_INCIDENT_WIDGET_VALUE,
            payload: widgetType,
        })
        REST.put(USER_PREFS_URL, {
            incidentWidgetOnDashboard: widgetType,
        })
    }
}

export function toggleFilterExpanded(
    filterExpanded: FilterExpandedType,
): ThunkAction<void, AppState, Api, Actions.ToggleFilterExpandedAction> {
    return (dispatch, getState) => {
        const currentFilter = getState().incidentsReportFilter
        const currentVesselFilter = getState().vesselFilter
        dispatch({
            type: ActionType.TOGGLE_FILTER_EXPANDED,
            payload: filterExpanded,
        })
        REST.put(USER_PREFS_URL, {
            selectedFilterName: currentFilter.selectedFilterName,
            locations: currentVesselFilter.locations,
            searchVesselTagTerm: currentVesselFilter.searchVesselTagTerm,
            searchVesselNameTerm: currentVesselFilter.searchVesselNameTerm,
            filterExpanded,
        })
    }
}

export function resetFilter(): ThunkAction<void, AppState, Api, AnyAction> {
    return (dispatch) => {
        dispatch({type: ActionType.RESET_FILTER})
        dispatch(setCurrentAppliedSavedFilter(undefined))
        REST.put(USER_PREFS_URL, {
            selectedFilterName: '',
            searchVesselTagTerm: [],
            searchVesselNameTerm: '',
        })
    }
}

export function bulkAssignSavedFilter(
    data: IncidentsReportFilter,
    filterName: string,
    pref?: boolean,
    savedFilter?: SavedIncidentsReportFilter,
): ThunkAction<void, AppState, Api, AnyAction> {
    return (dispatch, getState) => {
        const currentVesselFilter = getState().vesselFilter
        dispatch({
            type: ActionType.BULK_ASSIGN_SAVED_FILTER,
            payload: {
                criteria: data,
                name: filterName,
            },
        })
        savedFilter && dispatch(setCurrentAppliedSavedFilter(savedFilter))
        pref &&
            REST.put(USER_PREFS_URL, {
                selectedFilterName: filterName,
                locations: populateLocationsForFilterCriteria(currentVesselFilter.locations),
                searchVesselTagTerm: currentVesselFilter.searchVesselTagTerm,
                searchVesselNameTerm: currentVesselFilter.searchVesselNameTerm,
                filterExpanded: DEFAULT_INCIDENTS_REPORT_FILTER_STATE.filterExpanded,
            })
    }
}

export function setSelectedFilterName(
    filterName: string,
    pref?: {
        savedFilter: SavedIncidentsReportFilter
    },
): ThunkAction<void, AppState, Api, AnyAction> {
    return (dispatch, getState) => {
        const currentVesselFilter = getState().vesselFilter
        dispatch({
            type: ActionType.SET_SELECTED_FILTER_NAME,
            payload: filterName,
        })
        if (pref) {
            dispatch(setCurrentAppliedSavedFilter(pref.savedFilter))
            REST.put(USER_PREFS_URL, {
                selectedFilterName: filterName,
                locations: populateLocationsForFilterCriteria(currentVesselFilter.locations),
                searchVesselTagTerm: currentVesselFilter.searchVesselTagTerm,
                searchVesselNameTerm: currentVesselFilter.searchVesselNameTerm,
                filterExpanded: DEFAULT_INCIDENTS_REPORT_FILTER_STATE.filterExpanded,
            })
        }
    }
}

export const fetchUserPrefsIfExistForDashboard = (
    isDefaultTagUser?: boolean,
): ThunkAction<void, AppState, Api, Actions.Action> => {
    return async (dispatch, getState) => {
        dispatch(requestFilter())
        const activeLocation = getState().locations.activeLocation
        const location = isActiveLocation(activeLocation) ? activeLocation.location : null

        try {
            const response = await REST.get(USER_PREFS_URL)

            if (isDefaultTagUser === true) {
                await handleDefaultTagUserCase(dispatch, response, location)
            } else {
                dispatch(
                    receiveFilter(
                        formattedLocationsBlockSet([location]),
                        DEFAULT_INCIDENTS_REPORT_FILTER_STATE.searchVesselTagTerm,
                        response.data.value.incidentWidgetOnDashboard,
                    ),
                )
            }
        } catch (error) {
            dispatch(
                receiveFilter(
                    populateLocationsForFilterCriteria(
                        DEFAULT_INCIDENTS_REPORT_FILTER_STATE.locations,
                    ),
                    DEFAULT_INCIDENTS_REPORT_FILTER_STATE.searchVesselTagTerm,
                    DEFAULT_INCIDENTS_REPORT_FILTER_STATE.incidentWidgetOnDashboard,
                ),
            )
        }
    }
}
function receiveFilter(
    locations: LocationIdType[] | undefined,
    searchVesselTagTerm: string[],
    incidentWidgetOnDashboard: IncidentWidgetType,
): Actions.ReceiveFilterAction {
    return {
        type: ActionType.RECEIVE_FILTER,
        payload: {locations, searchVesselTagTerm, incidentWidgetOnDashboard},
    }
}
const handleDefaultTagUserCase = async (
    dispatch: Dispatch,
    response: AxiosResponse,
    location: string | null,
) => {
    try {
        const res = await REST.get(`${USER_PREFS_URL}.default_tag`)

        dispatch(
            receiveFilter(
                formattedLocationsBlockSet([location]),
                res.data.value.default_tag,
                response.data.value.incidentWidgetOnDashboard,
            ),
        )
    } catch (error) {
        dispatch(
            receiveFilter(
                populateLocationsForFilterCriteria(DEFAULT_INCIDENTS_REPORT_FILTER_STATE.locations),
                DEFAULT_INCIDENTS_REPORT_FILTER_STATE.searchVesselTagTerm,
                DEFAULT_INCIDENTS_REPORT_FILTER_STATE.incidentWidgetOnDashboard,
            ),
        )
    }
}
