import {useDimensions} from '../../contexts/dimensions/use-dimensions'
import {AssetDiscoveryWidget} from './components/asset-discovery/asset-discovery-widget'
import {FleetScoreOverTimeWidget} from './components/fleet-score-over-time/fleet-score-over-time-widget'
import {CenteredContentArea} from '../../components/centered-content-area.styled'
import {WidgetGrid} from './components/general/widget-grid.styled'
import {WidgetHolder} from './components/general/widget-holder.styled'
import {IncidentResponseWidget} from './components/incident-response/incident-response-widget'
import {MyFleetWidget} from './components/my-fleet/my-fleet-widget'
import {VesselsAtRiskWidget} from './components/vessels-at-risk/vessels-at-risk-widget'
import {WidgetType} from './widget-type'
import {useEffect, useRef, useState} from 'react'
import useTypedSelector from '../../hooks/use-typed-selector'
import {ets, incidentsOverview, latestMonaAnomalies, QueryManager} from '../../websocket/Queries'
import {activeLocationSelector} from '../../store/state/locations/selectors'
import {fetchEts} from '../../store/state/ets-value/action-creators'
import {fetchTrend} from '../../store/state/ets-trend/action-creators'
import {LAST_12H, LAST_24H, LAST_30D, LAST_7D, LAST_90D} from '../../values/TimeRange'
import {useReduxDispatch} from '../../hooks/useReduxDispatch'
import {WatchedNodesWidget} from './components/watched-nodes/watched-nodes-widget'
import {ScrollablePageTemplate} from '../../templates/scrollable-page/scrollable-page-template'
import {viewPageEvent} from '../../core/google-tag-manager/dashboard/view-page-event'
import {etsDataSelector} from '../../store/state/ets-value/selectors'
import {getScoreColor} from '../../core/google-tag-manager/dashboard/helper'
import {loadingReselector} from './components/my-fleet/reselect/loading.reselector'
import {normaliseScore} from '../../helpers/formatting'
import {threatMeasuresSelector} from '../../store/state/threat-measures/selectors'
import {NodeValues} from '../../values/nodes/NodeData'
import {registerLatestUnknownDataQuery} from '../../store/state/latest-unknown-asset/action-creators'
import {isActiveLocation} from '../../store/state/locations/state'
import {registerIncidentsOverviewQuery} from '../../store/state/incident-overview/action-creators'
import {TagsWidget} from './components/tags-component/tags-widget'
import {fleetConfigSelector} from '../../store/state/config/selectors'
import {isInternalUserSelector} from '../../store/state/current-user/selectors'
import {DisabledDataWidget} from './components/disabled-data/disabled-data-widget'
import {logPageAccess} from '../../store/state/audit-log/action-creators'
import {AuditLogAuditType, AuditLogPageType} from '../../store/state/audit-log/state'

export function Dashboard(): JSX.Element {
    const {width} = useDimensions()
    const dispatch = useReduxDispatch()
    const queryManager = useRef(new QueryManager())
    const activeLocation = useTypedSelector(activeLocationSelector)
    const location = isActiveLocation(activeLocation) ? activeLocation.location : null
    const isLoading = useTypedSelector(loadingReselector)
    const etsData = useTypedSelector(etsDataSelector)
    const threatMeasure = useTypedSelector(threatMeasuresSelector).get(NodeValues.HIGH)
    const score = etsData ? Math.round(normaliseScore(etsData.score)) : 'No data'
    const scoreLevel = getScoreColor(score, threatMeasure)
    const [isLoaded, setIsLoaded] = useState(true)
    const isInternalUser = useTypedSelector(isInternalUserSelector)
    const fleetConfig = useTypedSelector(fleetConfigSelector)

    useEffect(() => {
        if (isLoading === false && isLoaded === true) {
            viewPageEvent(location, scoreLevel)
            setIsLoaded(false)
            dispatch(logPageAccess(AuditLogPageType.DASHBOARD, AuditLogAuditType.PAGE_ACCESS))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoaded, isLoading, location, scoreLevel])

    useEffect(() => {
        const currentQueryManager = queryManager.current
        currentQueryManager.save(ets, dispatch(fetchEts(location)))
        currentQueryManager.save('ets-trend-12h', dispatch(fetchTrend(LAST_12H, location)))
        currentQueryManager.save('ets-trend-24h', dispatch(fetchTrend(LAST_24H, location)))
        currentQueryManager.save('ets-trend-7d', dispatch(fetchTrend(LAST_7D, location)))
        currentQueryManager.save('ets-trend-30d', dispatch(fetchTrend(LAST_30D, location)))
        currentQueryManager.save('ets-trend-90d', dispatch(fetchTrend(LAST_90D, location)))
        currentQueryManager.save(
            incidentsOverview,
            dispatch(registerIncidentsOverviewQuery(location)),
        )
        currentQueryManager.save(
            latestMonaAnomalies,
            dispatch(registerLatestUnknownDataQuery(location)),
        )
        return () => {
            currentQueryManager.cancelAll()
        }
    }, [dispatch, location])

    return (
        <ScrollablePageTemplate>
            <CenteredContentArea>
                <WidgetGrid width={width} id="dashboard-widget-grid">
                    <WidgetHolder
                        width={width}
                        widgetType={WidgetType.MY_FLEET}
                        id={`dashboard-widget-grid-${WidgetType.MY_FLEET}`}
                    >
                        <MyFleetWidget />
                    </WidgetHolder>
                    <WidgetHolder
                        width={width}
                        widgetType={WidgetType.INCIDENT_RESPONSE}
                        id={`dashboard-widget-grid-${WidgetType.INCIDENT_RESPONSE}`}
                    >
                        <IncidentResponseWidget location={location} />
                    </WidgetHolder>
                    <WidgetHolder
                        width={width}
                        widgetType={WidgetType.VESSELS_AT_RISK}
                        id={`dashboard-widget-grid-${WidgetType.VESSELS_AT_RISK}`}
                    >
                        <VesselsAtRiskWidget />
                    </WidgetHolder>
                    <WidgetHolder
                        width={width}
                        widgetType={WidgetType.RISK_SCORE_OVER_TIME}
                        id={`dashboard-widget-grid-${WidgetType.RISK_SCORE_OVER_TIME}`}
                    >
                        <FleetScoreOverTimeWidget queryManager={queryManager} />
                    </WidgetHolder>
                    <WidgetHolder
                        width={width}
                        widgetType={WidgetType.ASSET_DISCOVERY}
                        id={`dashboard-widget-grid-${WidgetType.ASSET_DISCOVERY}`}
                    >
                        {isInternalUser || fleetConfig.networkAssets ? (
                            <AssetDiscoveryWidget />
                        ) : (
                            <DisabledDataWidget type={WidgetType.ASSET_DISCOVERY} />
                        )}
                    </WidgetHolder>
                    <WidgetHolder
                        width={width}
                        widgetType={WidgetType.WATCHED_NODES}
                        placeHolder
                        id={`dashboard-widget-grid-${WidgetType.WATCHED_NODES}`}
                    >
                        <WatchedNodesWidget />
                    </WidgetHolder>
                    <WidgetHolder
                        width={width}
                        widgetType={WidgetType.TAGS_TABLE}
                        placeHolder
                        id={`dashboard-widget-grid-${WidgetType.TAGS_TABLE}`}
                    >
                        <TagsWidget queryManager={queryManager} />
                    </WidgetHolder>
                </WidgetGrid>
            </CenteredContentArea>
        </ScrollablePageTemplate>
    )
}
