import {createSelector} from 'reselect'
import {isIncidentsOverviewLoadingSelector} from '../../../../../store/state/incident-overview/selectors'
import {locationsLoadingSelector} from '../../../../../store/state/locations/selectors'
import {nodesFetchingSelector} from '../../../../../store/state/nodes/selectors'
import {incidentsReportFilterLoadingSelector} from '../../../../../store/state/incidents-report-filter/selectors'

export const loadingReselector = createSelector(
    locationsLoadingSelector,
    isIncidentsOverviewLoadingSelector,
    nodesFetchingSelector,
    incidentsReportFilterLoadingSelector,
    (
        locationsLoading: boolean,
        incidentsOverviewLoading: boolean,
        nodesFetching: boolean,
        incidentsReportFilterLoading: boolean,
    ): boolean => {
        return (
            locationsLoading ||
            incidentsOverviewLoading ||
            nodesFetching ||
            incidentsReportFilterLoading
        )
    },
)
