import AppState from '../../types/app-state'
import LoadingState from '../../../values/loading-state-enum'
import {IncidentsReportFilterReduxState} from './state'
import {IncidentWidgetType} from '../../../pages/incidents-report/contexts/types/incident-widget-type'

export const incidentsReportFilterLoadingStateSelector = (state: AppState): LoadingState =>
    state.incidentsReportFilter.loadingState

export const incidentsReportFilterLoadingSelector = (state: AppState): boolean =>
    state.incidentsReportFilter.loadingState === LoadingState.RequestingData ||
    state.incidentsReportFilter.loadingState === LoadingState.NotPopulated

export const incidentsReportFilterSelector = (state: AppState): IncidentsReportFilterReduxState =>
    state.incidentsReportFilter

export const fromDatePeriodSelector = (state: AppState): string | null =>
    state.incidentsReportFilter.fromDate

export const toDatePeriodSelector = (state: AppState): string | null =>
    state.incidentsReportFilter.toDate

export const selectedFilterNameSelector = (state: AppState): string =>
    state.incidentsReportFilter.selectedFilterName

export const activeFilterSelector = (state: AppState): boolean =>
    state.incidentsReportFilter.fromDate !== undefined ||
    state.incidentsReportFilter.toDate !== undefined

export const selectedIncidentWidgetSelector = (state: AppState): IncidentWidgetType =>
    state.incidentsReportFilter.incidentWidgetOnDashboard
