import {DataLoading} from '../../../../../components/data-loading/data-loading'
import {VesselPerformanceWidget} from '../../../../incidents-report/components/incidents-response-performance/vessel-performance-widget'
import {usePagedIncidentsReport} from '../../../../incidents-report/contexts/use-paged-incidents-report'
import * as Styled from './widget-commonstyling.styled'

export function VesselsPerformanceWidget(): JSX.Element {
    const {loadedIncidentsByVesselPerformance} = usePagedIncidentsReport()
    return (
        <Styled.StatusFilterContainer id="trends-section-trends-severity-container">
            <Styled.SectionText>VESSELS PERFORMANCE</Styled.SectionText>
            {loadedIncidentsByVesselPerformance ? <VesselPerformanceWidget /> : <DataLoading />}
        </Styled.StatusFilterContainer>
    )
}
