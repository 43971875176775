import {PopulatedData} from './populated-data'
import {NoData} from './no-data'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {Level} from '../../../../../values/location-level-enum'
import {latestIncidentResponseSelector} from '../../../../../store/state/incident-overview/selectors'
import {useEffect} from 'react'
import {usePagedIncidentsReport} from '../../../../incidents-report/contexts/use-paged-incidents-report'
import {IncidentWidgetType} from '../../../../incidents-report/contexts/types/incident-widget-type'
import {selectedIncidentWidgetSelector} from '../../../../../store/state/incidents-report-filter/selectors'

export function DataLoaded({location}: {location?: string}): JSX.Element {
    const {setWidgetOnDashboardData} = usePagedIncidentsReport()
    const incidentsWidgetOnDashboard = useTypedSelector(selectedIncidentWidgetSelector)
    useEffect(() => {
        if (incidentsWidgetOnDashboard !== IncidentWidgetType.LATEST_INCIDENT) {
            setWidgetOnDashboardData(location)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [incidentsWidgetOnDashboard])

    const latestIncident = useTypedSelector(latestIncidentResponseSelector)
    return latestIncident == null ? <NoData level={Level.UNKNOWN} /> : <PopulatedData />
}
