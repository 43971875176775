import {DataLoading} from '../../../../../components/data-loading/data-loading'
import {IncidentsTrendsWidget} from '../../../../incidents-report/components/incidents-types-and-severities/incidents-trends-widget'
import {usePagedIncidentsReport} from '../../../../incidents-report/contexts/use-paged-incidents-report'
import * as Styled from './widget-commonstyling.styled'

export function TrendsIncidentsWidget(): JSX.Element {
    const {loadedIncidentsTrends} = usePagedIncidentsReport()
    return (
        <Styled.StatusFilterContainer id="trends-section-trends-severity-container">
            <Styled.SectionText>RAISED INCIDENTS TREND</Styled.SectionText>
            {loadedIncidentsTrends ? <IncidentsTrendsWidget /> : <DataLoading />}
        </Styled.StatusFilterContainer>
    )
}
