import {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {AuditLogAuditType, AuditLogPageType} from '../../store/state/audit-log/state'
import {logPageAccess} from '../../store/state/audit-log/action-creators'
import {FixedPageTemplate} from '../../templates/fixed-page/fixed-page-template'
import {useDimensions} from '../../contexts/dimensions/use-dimensions'
import {setPageType} from '../../store/state/vessel-filter/action-creators'
import {PageType} from '../../components/vessel-filters/data-helper'
import {ScrollablePageTemplate} from '../../templates/scrollable-page/scrollable-page-template'
import * as Styled from './incidents-report-page.styled'
import {SHOW_FILTER_BAR_ON_BREAK_POINT} from './incidents-report-page.styled'
import {
    setCurrentAppliedSavedFilter,
    setSavedFilterPageType,
} from '../../store/state/saved-filters/action-creators'
import {SavedFilterPageType} from '../../store/state/saved-filters/state'
import useTypedSelector from '../../hooks/use-typed-selector'
import {currentUserSelector} from '../../store/state/current-user/selectors'
import {savedIncidentsReportFiltersSelector} from '../../store/state/saved-filters/selectors'
import {fetchIncidentsReportFilter} from '../../store/state/incidents-report-filter/action-creators'
import {StrictMode} from '../../store/state/users/state'
import {FilterBar} from './components/filter-bar/filter-bar'
import {incidentsReportFilterLoadingStateSelector} from '../../store/state/incidents-report-filter/selectors'
import LoadingState from '../../values/loading-state-enum'
import {DataLoading} from '../../components/data-loading/data-loading'
import {PagedIncidentsReportProvider} from './contexts/paged-incidents-report-provider'
import {usePagedIncidentsReport} from './contexts/use-paged-incidents-report'
import {Content} from './components/content'
import {usersLoadingStateSelector} from '../../store/state/users/selectors'
import {fetchUsers} from '../../store/state/users/action-creators'

export function IncidentsReportPage(): JSX.Element {
    const dispatch = useDispatch()
    const usersLoadingState = useTypedSelector(usersLoadingStateSelector)

    useEffect(() => {
        dispatch(logPageAccess(AuditLogPageType.INCIDENTS_REPORT, AuditLogAuditType.PAGE_ACCESS))
        dispatch(setCurrentAppliedSavedFilter(undefined))
    }, [])

    useEffect(() => {
        if (usersLoadingState === LoadingState.NotPopulated) {
            dispatch(fetchUsers())
        }
    }, [dispatch, usersLoadingState])

    return (
        <PagedIncidentsReportProvider>
            <FixedPageTemplate>
                <IncidentsReport />
            </FixedPageTemplate>
        </PagedIncidentsReportProvider>
    )
}
function IncidentsReport(): JSX.Element {
    const {width} = useDimensions()
    const {showFilterBar, displayFilterBar} = usePagedIncidentsReport()
    const dispatch = useDispatch()
    const {assignedTag, strictMode} = useTypedSelector(currentUserSelector)
    const savedFilters = useTypedSelector(savedIncidentsReportFiltersSelector)
    const showFilterBarOn = showFilterBarOnScreen(width)
    const filterLoading = useTypedSelector(incidentsReportFilterLoadingStateSelector)

    useEffect(() => {
        dispatch(setPageType(PageType.INCIDENTS_REPORT))
        dispatch(setSavedFilterPageType(SavedFilterPageType.INCIDENTS_REPORT))
        dispatch(
            fetchIncidentsReportFilter(strictMode === StrictMode.DefaultVisibility, savedFilters),
        )
    }, [assignedTag, strictMode])

    if (!showFilterBarOn && showFilterBar) {
        return (
            <ScrollablePageTemplate onClick={() => displayFilterBar(false)}>
                <Styled.FilterBarWrapper
                    width={width}
                    onClick={(e) => e.stopPropagation()}
                    id="filter-abr-wrapper"
                >
                    <FilterBar />
                </Styled.FilterBarWrapper>
            </ScrollablePageTemplate>
        )
    } else {
        return (
            <Styled.WideContentArea>
                {showFilterBar && (
                    <Styled.LHFilterBar id="filter-abr-wrapper">
                        <FilterBar />
                    </Styled.LHFilterBar>
                )}
                {filterLoading === LoadingState.Loaded ? <Content /> : <DataLoading />}
            </Styled.WideContentArea>
        )
    }
}

function showFilterBarOnScreen(width: number): boolean {
    return width > SHOW_FILTER_BAR_ON_BREAK_POINT
}
