import {Header} from '../../general/header/header.styled'
import {HeaderContent} from '../../general/header/header-content.styled'
import {Widget} from '../../general/widget.styled'
import {TitleText} from '../../general/header/title-text.styled'
import {DetailsButton} from '../../general/header/details-button/details-button'
import {ReactNode, useState} from 'react'
import {GridLayout} from '../../general/grid-layout.enum'
import {HeaderNewIndicator} from '../../general/header/new-indicator/new-indicator'
import {Level} from '../../../../../values/location-level-enum'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {viewIncidentsEvent} from '../../../../../core/google-tag-manager/navigation/view-incidents-event'
import {hasNewIncidentsSelector} from '../../../../../store/state/incident-overview/selectors'
import {CogButton} from '../../general/header/cog-button/cog-button'
import {IncidentsReportOptions} from './incidents-report-options'

interface WidgetWrapperProps {
    gridLayout: GridLayout
    children?: ReactNode | ReactNode[]
    level: Level
    noData?: boolean
}

export function WidgetWrapper({
    gridLayout,
    children,
    level,
    noData = false,
}: WidgetWrapperProps): JSX.Element {
    const showNewIncidentsIndicator = useTypedSelector(hasNewIncidentsSelector)
    const [openIncidentsWidgetDropDown, setOpenIncidentsWidgetDropDown] = useState(false)
    return (
        <Widget>
            <Header gridLayout={gridLayout} id="incident-response-widget-header">
                {showNewIncidentsIndicator && <HeaderNewIndicator gridLayout={gridLayout} />}
                <HeaderContent
                    gridLayout={gridLayout}
                    reducedLeftPadding={showNewIncidentsIndicator}
                >
                    <TitleText gridLayout={gridLayout} id="incident-response-widget-title">
                        {level !== Level.VESSEL ? 'Incidents' : 'Incident Response'}
                    </TitleText>
                    {!noData && (
                        <DetailsButton
                            linkTo="/incidents"
                            linkIdPrefix="incidents"
                            onClick={() => viewIncidentsEvent('dashboardWidget')}
                        />
                    )}
                    <CogButton
                        filterApplied={openIncidentsWidgetDropDown}
                        onClick={() => setOpenIncidentsWidgetDropDown(!openIncidentsWidgetDropDown)}
                        widget="tags"
                    />
                </HeaderContent>
            </Header>
            {openIncidentsWidgetDropDown && (
                <IncidentsReportOptions
                    setOpenIncidentsWidgetDropDown={setOpenIncidentsWidgetDropDown}
                />
            )}
            {children}
        </Widget>
    )
}
