import {Loading} from './components/loading'
import {DataLoaded} from './components/data-loaded'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {loadingReselector} from './reselect/loading.reselector'
import {currentUserSelector} from '../../../../store/state/current-user/selectors'
import {useDispatch} from 'react-redux'
import {fetchUserPrefsIfExistForDashboard} from '../../../../store/state/incidents-report-filter/action-creators'
import {StrictMode} from '../../../../store/state/users/state'
import {useEffect} from 'react'
import {PagedIncidentsReportProvider} from '../../../incidents-report/contexts/paged-incidents-report-provider'

export function IncidentResponseWidget({location}: {location: string | null}): JSX.Element {
    const {assignedTag, strictMode} = useTypedSelector(currentUserSelector)
    const dispatch = useDispatch()
    const formattedLocation = location != null ? location : undefined
    useEffect(() => {
        dispatch(fetchUserPrefsIfExistForDashboard(strictMode === StrictMode.DefaultVisibility))
    }, [assignedTag, strictMode, location])

    return useTypedSelector(loadingReselector) ? (
        <Loading />
    ) : (
        <PagedIncidentsReportProvider>
            <DataLoaded location={formattedLocation} />
        </PagedIncidentsReportProvider>
    )
}
