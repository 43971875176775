import {ThunkAction} from 'redux-thunk'
import {LocationIdType} from '../locations/state'
import ActionType from './action-type'
import * as Actions from './actions'
import {
    SORT_DIRECTION_TYPE,
    VesselFilter,
    VesselFilterForCriteria,
    VesselFilterReduxState,
} from './types/vessel-filter-state'
import {REST} from '../../../index'
import {Api} from '../../../api/Api'
import AppState from '../../types/app-state'
import {StringUtils, populateLocationsForFilterCriteria} from '../../../utils/Utils'
import {PageType} from '../../../components/vessel-filters/data-helper'
import {VesselFiltersState} from '../my-vessels-filters/types/my-vessel-filter-state'
import {ReportsFilterReduxState} from '../reports-filter/state'
import {
    DetailExpandedType as DetailExpandedTypeNetworkAssets,
    NetworkAssetsFilterReduxState,
} from '../network-assets-filter/state'
import {
    DetailExpandedType as DetailExpandedTypeUsbInventory,
    UsbInventoryFilterReduxState,
} from '../usb-inventory-filter/state'
import {MyVesselsFilter} from '../../../values/user-preferences/my-vessels-filter'
import {ReportsFilterForUserPref} from '../../../values/user-preferences/reports-filter'
import {NetworkAssetsFilterForUserPref} from '../../../values/user-preferences/network-assets-filter'
import {UsbInventoryFilterForUserPref} from '../../../values/user-preferences/usb-inventory-filter'
import {
    DetailExpandedType as DetailExpandedTypeSoftwareInventory,
    SoftwareInventoryFilterReduxState,
} from '../software-inventory-filter/state'
import {SoftwareInventoryFilterForUserPref} from '../../../values/user-preferences/software-inventory-filter'
import {AllFilterCriteriaType, AllFilterStateType} from './types/all-filter-state-type'
import {IncidentsFilterReduxState} from '../incidents-filter/state'
import {IncidentsFilterForUserPref} from '../../../values/user-preferences/incidents-filter'
import {MetricsBetaFilterForUserPref} from '../../../values/user-preferences/metrics-beta-filter'
import {MetricsBetaFilterReduxState} from '../metrics-filter-beta/state'
import {
    TimestampFilterType,
    formatTimestampCriteria,
} from '../../../pages/vessels-beta/contexts/types/timestamp-filter'
import {VesselsBetaFilterReduxState} from '../vessels-beta-filter/state'
import {VesselsBetaFilterForUserPref} from '../../../values/user-preferences/vessels-beta-filter'
import {VesselManagementFilterReduxState} from '../vessel-management-filter/state'
import {VesselManagementFilterForUserPref} from '../../../values/user-preferences/vessel-management-filter'

const USER_PREFS_URL = '/api/v1/userPreferences'

function buildLocations(
    allLocations: LocationIdType[],
    currentSelectedLocations: Set<LocationIdType> | undefined,
    location: LocationIdType | LocationIdType[],
    newValue: boolean,
): string | LocationIdType[] {
    const newLocations = new Set<LocationIdType>(
        currentSelectedLocations != undefined ? currentSelectedLocations : allLocations,
    )
    if (StringUtils.validString(location)) {
        if (newValue) {
            newLocations.add(location)
        } else {
            newLocations.delete(location)
        }
    } else if (Array.isArray(location)) {
        if (newValue) {
            location.forEach((e) => newLocations.add(e))
        } else {
            location.forEach((e) => newLocations.delete(e))
        }
    }

    if (newLocations.size === 0) {
        return 'all-deselected'
    }

    if (newLocations.size === allLocations?.length) {
        return 'all-selected'
    }

    return Array.from(newLocations)
}

export function setPageType(pageType: PageType): Actions.SetPageTypeAction {
    return {
        type: ActionType.SET_PAGE_TYPE,
        payload: pageType,
    }
}

export function setVesselFilterPref(
    vesselFilter: VesselFilterReduxState,
    pageType: PageType,
): Actions.SetVesselFilterPrefAction {
    return {
        type: ActionType.SET_VESSEL_FILTER_PREF,
        payload: {vesselFilter, pageType},
    }
}

export const onVesselSort = (
    field: string,
    direction: SORT_DIRECTION_TYPE,
    userPrefsKey: PageType,
): ThunkAction<void, AppState, Api, Actions.VesselSortAction> => {
    return (dispatch, getState) => {
        const currentFilter = getState().vesselFilter
        const allFilter = getState().myVesselsFilter
        const vesselSort = {
            field,
            direction,
        }
        dispatch({
            type: ActionType.VESSEL_SORT,
            payload: vesselSort,
        })
        REST.put(`${USER_PREFS_URL}/${userPrefsKey}`, {
            ...buildFilterPrefsMyVessel(currentFilter, allFilter),
            vesselSort: vesselSort,
        })
    }
}

export const toggleVessel = (
    location: LocationIdType,
    newValue: boolean,
    userPrefsKey: PageType,
): ThunkAction<void, AppState, Api, Actions.ToggleVesselAction> => {
    return (dispatch, getState) => {
        const locations = getState().locations.locations
        const allLocations = locations.map((location) => location.location)

        const currentFilter = getState().vesselFilter
        const allFilter = getAllFilter(userPrefsKey, getState)
        dispatch({
            type: ActionType.TOGGLE_VESSEL,
            payload: {
                allLocations,
                location,
                newValue,
            },
        })
        REST.put(`${USER_PREFS_URL}/${userPrefsKey}`, {
            ...buildFilterPrefs(currentFilter, allFilter, userPrefsKey),
            locations: buildLocations(allLocations, currentFilter.locations, location, newValue),
        })
    }
}

export const toggleFilteredVessels = (
    filteredLocations: LocationIdType[],
    newValue: boolean,
    userPrefsKey: PageType,
): ThunkAction<void, AppState, Api, Actions.ToggleFilteredVesselsAction> => {
    return (dispatch, getState) => {
        const locations = getState().locations.locations
        const allLocations = locations.map((location) => location.location)

        const currentFilter = getState().vesselFilter
        const allFilter = getAllFilter(userPrefsKey, getState)

        dispatch({
            type: ActionType.TOGGLE_FILTERED_VESSELS,
            payload: {
                allLocations,
                filteredLocations,
                newValue,
            },
        })

        REST.put(`${USER_PREFS_URL}/${userPrefsKey}`, {
            ...buildFilterPrefs(currentFilter, allFilter, userPrefsKey),
            locations: buildLocations(
                allLocations,
                currentFilter.locations,
                filteredLocations,
                newValue,
            ),
        })
    }
}

export const setSearchVesselTagTerm = (
    searchVesselTagTerm: string[],
    userPrefsKey: PageType,
    isDefaultTagUser?: boolean,
): ThunkAction<void, AppState, Api, Actions.Action> => {
    return (dispatch, getState) => {
        const currentFilter = getState().vesselFilter
        const allFilter = getAllFilter(userPrefsKey, getState)

        dispatch({
            type: ActionType.SET_SEARCH_VESSEL_TAG_TERM,
            payload: searchVesselTagTerm,
        })
        isDefaultTagUser === true
            ? REST.put(`${USER_PREFS_URL}/${userPrefsKey}.default_tag`, {
                  default_tag: searchVesselTagTerm,
              })
            : REST.put(`${USER_PREFS_URL}/${userPrefsKey}`, {
                  ...buildFilterPrefs(currentFilter, allFilter, userPrefsKey),
                  searchVesselTagTerm: searchVesselTagTerm,
              })
    }
}

export const setSearchVesselNameTerm = (
    searchVesselNameTerm: string,
    userPrefsKey: PageType,
): ThunkAction<void, AppState, Api, Actions.Action> => {
    return (dispatch, getState) => {
        const currentFilter = getState().vesselFilter
        const allFilter = getAllFilter(userPrefsKey, getState)

        dispatch({
            type: ActionType.SET_SEARCH_VESSEL_NAME_TERM,
            payload: searchVesselNameTerm,
        })

        REST.put(`${USER_PREFS_URL}/${userPrefsKey}`, {
            ...buildFilterPrefs(currentFilter, allFilter, userPrefsKey),
            searchVesselNameTerm: searchVesselNameTerm,
        })
    }
}

export const setSearchVesselTerm = (searchTerm: string): Actions.SetSearchVesselTermAction => {
    return {
        type: ActionType.SET_SEARCH_VESSEL_TERM,
        payload: searchTerm,
    }
}

export const setShowXButton = (value: boolean): Actions.SetShowXButtonAction => {
    return {
        type: ActionType.SET_SHOW_X_BUTTON,
        payload: value,
    }
}

export const resetVesselFilter = (): ThunkAction<void, AppState, Api, Actions.Action> => {
    return (dispatch) => {
        dispatch({
            type: ActionType.RESET_VESSEL_FILTER,
        })
    }
}

function buildFilterPrefsMyVessel(
    state: VesselFilter,
    allFilter: VesselFiltersState,
): VesselFilterForCriteria {
    return {
        ...buildMyVesselsFilterPrefs(allFilter),
        vesselSort: state.vesselSort,
        locations: populateLocationsForFilterCriteria(state.locations),
        searchVesselTagTerm: state.searchVesselTagTerm,
        searchVesselNameTerm: state.searchVesselNameTerm,
    }
}

function getAllFilter(userPrefsKey: PageType, getState: () => AppState): AllFilterStateType {
    switch (userPrefsKey) {
        case PageType.MY_VESSELS:
            return getState().myVesselsFilter
        case PageType.INCIDENTS:
            return getState().incidentsFilter
        case PageType.REPORTS:
            return getState().reportsFilter
        case PageType.NETWORK_ASSETS:
            return getState().networkAssetsFilter
        case PageType.USB_DEVICES:
            return getState().usbInventoryFilter
        case PageType.SOFTWARE_INVENTORY:
            return getState().softwareInventoryFilter
        case PageType.METRICS:
            return getState().metricsBetaFilter
        case PageType.VESSELS_BETA:
            return getState().vesselsBetaFilter
        case PageType.VESSEL_MANAGEMENT:
            return getState().vesselManagementFilter
        default:
            return getState().myVesselsFilter
    }
}

function buildFilterPrefs(
    state: VesselFilterReduxState,
    allFilter: AllFilterStateType,
    pageType: PageType,
): AllFilterCriteriaType {
    switch (pageType) {
        case PageType.MY_VESSELS:
            return {
                ...buildMyVesselsFilterPrefs(allFilter as VesselFiltersState),
                vesselSort: state.vesselSort,
                locations: populateLocationsForFilterCriteria(state.locations),
                searchVesselTagTerm: state.searchVesselTagTerm,
                searchVesselNameTerm: state.searchVesselNameTerm,
            } as MyVesselsFilter
        case PageType.INCIDENTS:
            return {
                ...buildIncidentsFilterPrefs(allFilter as IncidentsFilterReduxState),
                locations: populateLocationsForFilterCriteria(state.locations),
                searchVesselTagTerm: state.searchVesselTagTerm,
                searchVesselNameTerm: state.searchVesselNameTerm,
            } as IncidentsFilterForUserPref
        case PageType.REPORTS:
            return {
                ...buildReportsFilterPrefs(allFilter as ReportsFilterReduxState),
                locations: populateLocationsForFilterCriteria(state.locations),
                searchVesselTagTerm: state.searchVesselTagTerm,
                searchVesselNameTerm: state.searchVesselNameTerm,
            } as ReportsFilterForUserPref
        case PageType.NETWORK_ASSETS:
            return {
                ...buildNetworkAssetsFilterPrefs(allFilter as NetworkAssetsFilterReduxState),
                locations: populateLocationsForFilterCriteria(state.locations),
                searchVesselTagTerm: state.searchVesselTagTerm,
                searchVesselNameTerm: state.searchVesselNameTerm,
            } as NetworkAssetsFilterForUserPref
        case PageType.USB_DEVICES:
            return {
                ...buildUsbInventoryFilterPrefs(allFilter as UsbInventoryFilterReduxState),
                locations: populateLocationsForFilterCriteria(state.locations),
                searchVesselTagTerm: state.searchVesselTagTerm,
                searchVesselNameTerm: state.searchVesselNameTerm,
            } as UsbInventoryFilterForUserPref
        case PageType.SOFTWARE_INVENTORY:
            return {
                ...buildSoftwareInventoryFilterPrefs(
                    allFilter as SoftwareInventoryFilterReduxState,
                ),
                locations: populateLocationsForFilterCriteria(state.locations),
                searchVesselTagTerm: state.searchVesselTagTerm,
                searchVesselNameTerm: state.searchVesselNameTerm,
            } as SoftwareInventoryFilterForUserPref
        case PageType.METRICS:
            return {
                ...buildMetricsFilterPrefs(allFilter as MetricsBetaFilterReduxState),
                locations: populateLocationsForFilterCriteria(state.locations),
                searchVesselTagTerm: state.searchVesselTagTerm,
                searchVesselNameTerm: state.searchVesselNameTerm,
            } as MetricsBetaFilterForUserPref
        case PageType.VESSELS_BETA:
            return {
                ...buildVesselsBetaFilterPrefs(allFilter as VesselsBetaFilterReduxState),
                locations: populateLocationsForFilterCriteria(state.locations),
                searchVesselTagTerm: state.searchVesselTagTerm,
                searchVesselNameTerm: state.searchVesselNameTerm,
            } as VesselsBetaFilterForUserPref
        case PageType.VESSEL_MANAGEMENT:
            return {
                ...buildVesselManagementFilterPrefs(allFilter as VesselManagementFilterReduxState),
                locations: populateLocationsForFilterCriteria(state.locations),
                searchVesselTagTerm: state.searchVesselTagTerm,
                searchVesselNameTerm: state.searchVesselNameTerm,
            } as VesselManagementFilterForUserPref
        default:
            return {
                ...buildMyVesselsFilterPrefs(allFilter as VesselFiltersState),
                vesselSort: state.vesselSort,
                locations: populateLocationsForFilterCriteria(state.locations),
                searchVesselTagTerm: state.searchVesselTagTerm,
                searchVesselNameTerm: state.searchVesselNameTerm,
            } as MyVesselsFilter
    }
}

export function buildMyVesselsFilterPrefs(allFilter: VesselFiltersState): MyVesselsFilter {
    return {
        zoomLevel: allFilter.zoomLevel,
        vesselSort: allFilter.vesselSort,
        assetSort: allFilter.assetSort,
        HIGH: allFilter.HIGH,
        MEDIUM: allFilter.MEDIUM,
        LOW: allFilter.LOW,
        locations: populateLocationsForFilterCriteria(allFilter.locations),
        searchVesselTagTerm: allFilter.searchVesselTagTerm,
        searchVesselNameTerm: allFilter.searchVesselNameTerm,
    }
}

export function buildIncidentsFilterPrefs(
    allFilter: IncidentsFilterReduxState,
): IncidentsFilterForUserPref {
    return {
        selectedFilterName: allFilter.selectedFilterName,
        locations: populateLocationsForFilterCriteria(allFilter.locations),
        searchVesselTagTerm: allFilter.searchVesselTagTerm,
        searchVesselNameTerm: allFilter.searchVesselNameTerm,
        sortByNotes: allFilter.sortByNotes,
        detailExpanded: allFilter.detailExpanded,
    }
}

export function buildReportsFilterPrefs(
    allFilter: ReportsFilterReduxState,
): ReportsFilterForUserPref {
    return {
        selectedFilterName: allFilter.selectedFilterName,
        locations: populateLocationsForFilterCriteria(allFilter.locations),
        searchVesselTagTerm: allFilter.searchVesselTagTerm,
        searchVesselNameTerm: allFilter.searchVesselNameTerm,
    }
}

export function buildVesselsBetaFilterPrefs(
    allFilter: VesselsBetaFilterReduxState,
): VesselsBetaFilterForUserPref {
    return {
        analysisPeriod: allFilter.analysisPeriod,
        selectedColumns: allFilter.selectedColumns,
        sortColumn: allFilter.sortColumn,
        locations: populateLocationsForFilterCriteria(allFilter.locations),
        searchVesselTagTerm: allFilter.searchVesselTagTerm,
        searchVesselNameTerm: allFilter.searchVesselNameTerm,
        searchVesselTerm: allFilter.searchVesselTerm,
    }
}

export function buildNetworkAssetsFilterPrefs(
    allFilter: NetworkAssetsFilterReduxState,
): NetworkAssetsFilterForUserPref {
    return {
        selectedFilterName: allFilter.selectedFilterName,
        detailExpanded: allFilter.detailExpanded,
        locations: populateLocationsForFilterCriteria(allFilter.locations),
        searchVesselTagTerm: allFilter.searchVesselTagTerm,
        searchVesselNameTerm: allFilter.searchVesselNameTerm,
    }
}

export function buildUsbInventoryFilterPrefs(
    allFilter: UsbInventoryFilterReduxState,
): UsbInventoryFilterForUserPref {
    return {
        selectedFilterName: allFilter.selectedFilterName,
        locations: populateLocationsForFilterCriteria(allFilter.locations),
        searchVesselTagTerm: allFilter.searchVesselTagTerm,
        searchVesselNameTerm: allFilter.searchVesselNameTerm,
        detailExpanded: allFilter.detailExpanded,
    }
}

export function buildSoftwareInventoryFilterPrefs(
    allFilter: SoftwareInventoryFilterReduxState,
): SoftwareInventoryFilterForUserPref {
    return {
        selectedFilterName: allFilter.selectedFilterName,
        locations: populateLocationsForFilterCriteria(allFilter.locations),
        searchVesselTagTerm: allFilter.searchVesselTagTerm,
        searchVesselNameTerm: allFilter.searchVesselNameTerm,
        detailExpanded: allFilter.detailExpanded,
    }
}

export function buildMetricsFilterPrefs(
    allFilter: MetricsBetaFilterReduxState,
): MetricsBetaFilterForUserPref {
    return {
        selectedFilterName: allFilter.selectedFilterName,
        locations: populateLocationsForFilterCriteria(allFilter.locations),
        searchVesselTagTerm: allFilter.searchVesselTagTerm,
        searchVesselNameTerm: allFilter.searchVesselNameTerm,
        selectedAnalysisPeriod: allFilter.selectedAnalysisPeriod,
        analysisTypes: allFilter.analysisTypes,
        selectedViewScreenType: allFilter.selectedViewScreenType,
        filterExpanded: allFilter.filterExpanded,
        metricTypes: allFilter.metricTypes,
        assetValues: allFilter.assetValues,
    }
}

export function buildVesselManagementFilterPrefs(
    allFilter: VesselManagementFilterReduxState,
): VesselManagementFilterForUserPref {
    return {
        hasDeploymentIssues: allFilter.hasDeploymentIssues,
        locations: populateLocationsForFilterCriteria(allFilter.locations),
        searchVesselTagTerm: allFilter.searchVesselTagTerm,
        searchVesselNameTerm: allFilter.searchVesselNameTerm,
    }
}

export const toggleVesselForVesselBetaInterlinking = (
    location: LocationIdType,
    userPrefsKey: PageType,
    analysisType: TimestampFilterType,
): ThunkAction<void, AppState, Api, Actions.SetVesselForInterlinkingAction> => {
    return (dispatch, getState) => {
        const currentFilter = getState().vesselFilter
        const allFilter = getAllFilter(userPrefsKey, getState)

        dispatch({
            type: ActionType.SET_VESSEL_FOR_INTERLINKING,
            payload: location,
        })
        REST.put(`${USER_PREFS_URL}/${userPrefsKey}`, {
            ...buildFilterPrefsForInterlinkingVesselsBeta(
                currentFilter,
                allFilter,
                userPrefsKey,
                analysisType,
            ),
            fromRelativeLastSeen: formatTimestampCriteria(analysisType),
            locations: [location],
        })
    }
}
function buildFilterPrefsForInterlinkingVesselsBeta(
    state: VesselFilterReduxState,
    allFilter: AllFilterStateType,
    pageType: PageType,
    analysisType: TimestampFilterType,
    stateOfItems?: 'untrusted' | 'trusted',
): AllFilterCriteriaType {
    const stateCriteriaAssets =
        stateOfItems === 'untrusted'
            ? ['blocked']
            : stateOfItems === 'trusted'
              ? ['trusted']
              : undefined
    const stateCriteriaUsb =
        stateOfItems === 'untrusted'
            ? ['rejected']
            : stateOfItems === 'trusted'
              ? ['trusted']
              : undefined
    const stateCriteriaSoftware =
        stateOfItems === 'untrusted'
            ? ['unwanted']
            : stateOfItems === 'trusted'
              ? ['trusted']
              : undefined

    switch (pageType) {
        case PageType.NETWORK_ASSETS:
            return {
                ...buildNetworkAssetsFilterPrefs(allFilter as NetworkAssetsFilterReduxState),
                locations: populateLocationsForFilterCriteria(state.locations),
                searchVesselTagTerm: state.searchVesselTagTerm,
                searchVesselNameTerm: state.searchVesselNameTerm,
                fromRelativeLastSeen: formatTimestampCriteria(analysisType),
                states: stateCriteriaAssets,
            } as {
                selectedFilterName: string
                locations: LocationIdType[] | undefined
                searchVesselTagTerm: string[]
                searchVesselNameTerm: string
                detailExpanded: DetailExpandedTypeNetworkAssets
                fromRelativeLastSeen: string
                states: string[]
            }
        case PageType.USB_DEVICES:
            return {
                ...buildUsbInventoryFilterPrefs(allFilter as UsbInventoryFilterReduxState),
                locations: populateLocationsForFilterCriteria(state.locations),
                searchVesselTagTerm: state.searchVesselTagTerm,
                searchVesselNameTerm: state.searchVesselNameTerm,
                fromRelativeLastSeen: formatTimestampCriteria(analysisType),
                states: stateCriteriaUsb,
            } as {
                selectedFilterName: string
                locations: LocationIdType[] | undefined
                searchVesselTagTerm: string[]
                searchVesselNameTerm: string
                detailExpanded: DetailExpandedTypeUsbInventory
                fromRelativeLastSeen: string
                states: string[]
            }
        case PageType.SOFTWARE_INVENTORY:
            return {
                ...buildSoftwareInventoryFilterPrefs(
                    allFilter as SoftwareInventoryFilterReduxState,
                ),
                locations: populateLocationsForFilterCriteria(state.locations),
                searchVesselTagTerm: state.searchVesselTagTerm,
                searchVesselNameTerm: state.searchVesselNameTerm,
                fromRelativeLastSeen: formatTimestampCriteria(analysisType),
                states: stateCriteriaSoftware,
            } as {
                selectedFilterName: string
                locations: LocationIdType[] | undefined
                searchVesselTagTerm: string[]
                searchVesselNameTerm: string
                detailExpanded: DetailExpandedTypeSoftwareInventory
                fromRelativeLastSeen: string
                states: string[]
            }
        case PageType.INCIDENTS:
            return {
                ...buildIncidentsFilterPrefs(allFilter as IncidentsFilterReduxState),
                locations: populateLocationsForFilterCriteria(state.locations),
                searchVesselTagTerm: state.searchVesselTagTerm,
                searchVesselNameTerm: state.searchVesselNameTerm,
            } as IncidentsFilterForUserPref
        default:
            return {
                ...buildMyVesselsFilterPrefs(allFilter as VesselFiltersState),
                vesselSort: state.vesselSort,
                locations: populateLocationsForFilterCriteria(state.locations),
                searchVesselTagTerm: state.searchVesselTagTerm,
                searchVesselNameTerm: state.searchVesselNameTerm,
            } as MyVesselsFilter
    }
}
