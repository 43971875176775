import {
    ResponsiveContainer,
    CartesianGrid,
    XAxis,
    YAxis,
    Tooltip,
    AreaChart,
    ReferenceArea,
    Area,
} from 'recharts'
import {CyberOwlTheme} from '../../../../../../theme/theme'
import {useTheme} from 'styled-components'
import {usePagedMetricsBeta} from '../../../../contexts/use-paged-metrics-beta'
import {DataLoading} from '../../../../../../components/data-loading/data-loading'
import {NoData} from '../../../no-data/no-data'
import {
    MetricTrendsArray,
    MetricTrendsArrayForUIDisplay,
} from '../../../../contexts/types/metrics-summary'
import * as Styled from './trends-section.styled'
import {CurrentScoreTooltip} from '../../../metrics-summaries/current-score-tooltip'
import useTypedSelector from '../../../../../../hooks/use-typed-selector'
import {metricsBetaFilterSelector} from '../../../../../../store/state/metrics-filter-beta/selectors'
import {getColor} from '../../../metrics-summaries/score-bar.styled'
import {convertPeriodTimestampForTrends} from '../../../data-helper'

function formatTick(value: number) {
    return `${value}%`
}

function yAxis(theme: CyberOwlTheme): JSX.Element {
    const ticks = [0, 25, 50, 75, 100]
    return (
        <YAxis
            type="number"
            domain={[0, 100]}
            ticks={ticks}
            tickLine={true}
            fontSize="14px"
            color={theme.colors.text.default}
            fontWeight={theme.font.weight.semibold}
            tickFormatter={formatTick}
            id="y-axis"
        />
    )
}

export function TrendsSection(): JSX.Element {
    const theme = useTheme()

    const {isProcessingMetricDetailsModal, findMetricDetailsForModal} = usePagedMetricsBeta()
    const {selectedAnalysisPeriod, benchmarkType} = useTypedSelector(metricsBetaFilterSelector)

    if (!isProcessingMetricDetailsModal) {
        return (
            <Styled.Section>
                <Styled.Title>Assets with status OK</Styled.Title>
                <DataLoading />
            </Styled.Section>
        )
    }
    if (!findMetricDetailsForModal?.trends.length) {
        return (
            <Styled.Section>
                <Styled.Title>Assets with status OK</Styled.Title>
                <NoData text="No Assets are found" />
            </Styled.Section>
        )
    }

    function getTrendsArrayForUIDisplay(
        trendsArray: MetricTrendsArray[],
    ): MetricTrendsArrayForUIDisplay[] {
        if (!trendsArray) {
            return new Array<MetricTrendsArrayForUIDisplay>()
        }
        return trendsArray.map((trendItem) => ({
            timestamp: new Date(trendItem?.timestamp).getTime(),
            selectedVesselsPerformance: isNaN(
                trendItem.totalAssetsOk /
                    (trendItem.totalAssetsOk + trendItem.totalAssetsWithIssue),
            )
                ? 0
                : Math.round(
                      (trendItem.totalAssetsOk /
                          (trendItem.totalAssetsOk + trendItem.totalAssetsWithIssue)) *
                          100,
                  ),
            fleetPerformance: 0,
            allCustomerBenchmarkPerformance: 0,
        }))
    }
    const formattedData = getTrendsArrayForUIDisplay(findMetricDetailsForModal?.trends)
    const endDate = new Date()
    const startDate = new Date(endDate)
    const getPeriodData = convertPeriodTimestampForTrends(true, selectedAnalysisPeriod)

    switch (getPeriodData) {
        case '365d':
            startDate.setFullYear(endDate.getFullYear() - 1)
            break
        case '71d':
            startDate.setDate(endDate.getDate() - 71)
            break
        case '14d':
            startDate.setDate(endDate.getDate() - 14)
            break
        case '2h':
            startDate.setHours(endDate.getHours() - 2)
            break
        default:
            startDate.setFullYear(endDate.getFullYear() - 1)
    }
    return (
        <Styled.Section>
            <Styled.Title>Assets with status OK</Styled.Title>
            <ResponsiveContainer height="100%" width="100%" minHeight="230px">
                <AreaChart margin={{top: 15, right: 30, bottom: -10}} data={formattedData}>
                    <CartesianGrid strokeDasharray="3 3" fill="#f0f0f0" id="CartesianGrid" />
                    <XAxis
                        dataKey="timestamp"
                        type="number"
                        tick={false}
                        domain={[startDate.getTime(), endDate.getTime()]}
                        id="x-axis"
                    />
                    {yAxis(theme)}
                    <Tooltip
                        content={
                            <CurrentScoreTooltip
                                data={formattedData}
                                benchmarkType={benchmarkType}
                                showAdditionalInfo={false}
                            />
                        }
                    />
                    <ReferenceArea
                        x1={formattedData[0].timestamp}
                        x2={formattedData[formattedData.length - 1].timestamp}
                        fill="#ffffff"
                        fillOpacity={1}
                        id="area-background"
                        strokeWidth={5}
                    />
                    <Area
                        id="area-chart"
                        type="monotone"
                        dataKey="selectedVesselsPerformance"
                        fill={getColor(findMetricDetailsForModal.metricValue)}
                        stroke={theme.colors.graph.primary}
                        dot={{
                            stroke: theme.colors.graph.primary,
                            strokeWidth: 2,
                            fill: '#ffffff',
                        }}
                        fillOpacity={0.5}
                        isAnimationActive={true}
                    />
                </AreaChart>
            </ResponsiveContainer>
        </Styled.Section>
    )
}
