import {useDispatch} from 'react-redux'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {metricsBetaFilterSelector} from '../../../../../store/state/metrics-filter-beta/selectors'
import * as Styled from './analysis-selection.styled'
import {
    getAnalysisPeriodDisplayName,
    getAnalysisTypeDisplayName,
    isTrendsPeriodNeeded,
} from '../../data-helper'
import {TimestampFilterType} from '../../../../../store/state/metrics-filter-beta/state'
import {setAnalysisPeriod} from '../../../../../store/state/metrics-filter-beta/action-creators'
import {usePagedMetricsBeta} from '../../../contexts/use-paged-metrics-beta'
import {PageType} from '../../../../../components/vessel-filters/data-helper'
import {filterByEvent} from '../../../../../core/google-tag-manager/metrics-beta/filter-by-event'
import {logAnalysisPeriodOnFilter} from '../../../../../store/state/audit-log/action-creators'
import {AuditLogPageType} from '../../../../../store/state/audit-log/state'

export function PeriodSelectionFilter(): JSX.Element {
    const dispatch = useDispatch()
    const {selectedAnalysisPeriod, analysisTypes, selectedViewScreenType} =
        useTypedSelector(metricsBetaFilterSelector)
    const {highlightedFilerValue} = usePagedMetricsBeta()
    const allPeriodTypeOther = [TimestampFilterType.LAST_24_H, TimestampFilterType.LAST_7_DAYS]
    const allPeriodTypeTrend = [
        TimestampFilterType.ONE_FORTNIGHT,
        TimestampFilterType.ONE_QUARTER,
        TimestampFilterType.ONE_YEAR,
    ]

    function onChangeWrapper(event: React.ChangeEvent<HTMLSelectElement>): void {
        event.preventDefault()
        dispatch(setAnalysisPeriod(event.target.value as TimestampFilterType))
        filterByEvent('Analysis period', event.target.value)
        dispatch(
            logAnalysisPeriodOnFilter(
                getAnalysisPeriodDisplayName(event.target.value as TimestampFilterType),
                getAnalysisTypeDisplayName(analysisTypes),
                AuditLogPageType.METRICS,
            ),
        )
    }
    const trendsPeriodNeeded = isTrendsPeriodNeeded(analysisTypes, selectedViewScreenType)

    if (trendsPeriodNeeded) {
        return (
            <Styled.Wrapper
                highlightedFilerValue={highlightedFilerValue === 'Analysis period'}
                id={`${PageType.METRICS}_filter-bar_period-type-filter`}
            >
                <Styled.Label>Analysis Period:</Styled.Label>
                <Styled.Input>
                    <Styled.Select
                        onChange={onChangeWrapper}
                        value={selectedAnalysisPeriod}
                        id={`${PageType.METRICS}_filter-bar_searched-period-type-value`}
                    >
                        <option
                            key="latest"
                            value={TimestampFilterType.LAST_2_H}
                            id={`${PageType.METRICS}_filter-bar_period-type_latest-option`}
                        >
                            {getAnalysisPeriodDisplayName(TimestampFilterType.LAST_2_H)}
                        </option>
                        {allPeriodTypeTrend?.map((value) => (
                            <option
                                key={value}
                                value={value}
                                id={`${PageType.METRICS}_filter-bar_period-type_${value}-option`}
                            >
                                {getAnalysisPeriodDisplayName(value)}
                            </option>
                        ))}
                    </Styled.Select>
                </Styled.Input>
            </Styled.Wrapper>
        )
    }
    return (
        <Styled.Wrapper
            highlightedFilerValue={highlightedFilerValue === 'Analysis period'}
            id={`${PageType.METRICS}_filter-bar_period-type-filter`}
        >
            <Styled.Label>Analysis Period:</Styled.Label>
            <Styled.Input>
                <Styled.Select
                    onChange={onChangeWrapper}
                    value={selectedAnalysisPeriod}
                    id={`${PageType.METRICS}_filter-bar_searched-period-type-value`}
                >
                    <option
                        key="latest"
                        value={TimestampFilterType.LATEST}
                        id={`${PageType.METRICS}_filter-bar_period-type_latest-option`}
                    >
                        {getAnalysisPeriodDisplayName(TimestampFilterType.LATEST)}
                    </option>
                    {allPeriodTypeOther?.map((value) => (
                        <option
                            key={value}
                            value={value}
                            id={`${PageType.METRICS}_filter-bar_period-type_${value}-option`}
                        >
                            {getAnalysisPeriodDisplayName(value)}
                        </option>
                    ))}
                    <option
                        key="default-option"
                        value={TimestampFilterType.LAST_30_DAYS}
                        id={`${PageType.METRICS}_filter-bar_period-type_default-option`}
                    >
                        {getAnalysisPeriodDisplayName(TimestampFilterType.LAST_30_DAYS)}
                    </option>
                </Styled.Select>
            </Styled.Input>
        </Styled.Wrapper>
    )
}
