import {LinkedTitle, Title} from '../../general/content-area/title.styled'
import {IncidentData} from './incident-data'
import {HeadlineValues} from './headline-values'
import {TopContentSection} from '../../general/content-area/top-content-section.styled'
import {BottomContentSection} from '../../general/content-area/bottom-content-section'
import {Link} from 'react-router-dom'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {logInterlinksClick} from '../../../../../store/state/audit-log/action-creators'
import {AuditLogAuditType, AuditLogPageType} from '../../../../../store/state/audit-log/state'
import {useDispatch} from 'react-redux'
import {hasNewIncidentsSelector} from '../../../../../store/state/incident-overview/selectors'
import {incidentResponsesWidgetReselector} from '../reselect/incident-responses-widget.reselector'
import {getGridLayout} from '../../general/helpers'
import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'

export function LatestIncidentWidget(): JSX.Element {
    const dispatch = useDispatch()

    const titleText = useTypedSelector(hasNewIncidentsSelector)
        ? 'Latest Incident Update'
        : 'Latest Incident Alert'
    const {width} = useDimensions()

    const gridLayout = getGridLayout(width)

    const model = useTypedSelector(incidentResponsesWidgetReselector)
    return (
        <>
            <TopContentSection>
                <Link
                    to={{
                        pathname: `/incidents`,
                        search: `?incidentDetails=${model.identity}`,
                        state: model.incidentCode,
                    }}
                    onClick={() =>
                        model.identity != null &&
                        dispatch(
                            logInterlinksClick(
                                AuditLogPageType.INCIDENTS,
                                AuditLogAuditType.INTERLINK_CLICK,
                                model.identity,
                            ),
                        )
                    }
                    id="link-to-incidents-page"
                >
                    <LinkedTitle gridLayout={gridLayout} id="link-title">
                        {titleText}
                    </LinkedTitle>
                </Link>
                <IncidentData model={model} gridLayout={gridLayout} />
            </TopContentSection>
            <BottomContentSection>
                <Title gridLayout={gridLayout} id="incidents-bottom-section-title">
                    Incident Overview
                </Title>
                <HeadlineValues gridLayout={gridLayout} />
            </BottomContentSection>
        </>
    )
}
