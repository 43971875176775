import {DataLoading} from '../../../../../components/data-loading/data-loading'
import {TotalNumberOfIncidentsByStatus} from '../../../../incidents-report/components/status-of-incidents-raised/total-number-of-incidents-by-status'
import {usePagedIncidentsReport} from '../../../../incidents-report/contexts/use-paged-incidents-report'
import * as Styled from './widget-commonstyling.styled'

export function CurrentStatusWidget(): JSX.Element {
    const {loadedIncidentsCounts} = usePagedIncidentsReport()
    return (
        <Styled.StatusFilterContainer id="status-section-status-count-container">
            <Styled.SectionText>CURRENT STATUS OF INCIDENTS</Styled.SectionText>
            {loadedIncidentsCounts ? <TotalNumberOfIncidentsByStatus /> : <DataLoading />}
        </Styled.StatusFilterContainer>
    )
}
