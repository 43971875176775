import {Dispatch, SetStateAction} from 'react'
import {IncidentWidgetType} from '../../../../incidents-report/contexts/types/incident-widget-type'
import {getIncidentWidgetTypeDisplayName} from './get-incident-widget-type-display-name'
import * as Styled from './incidents-report-options.styled'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {selectedIncidentWidgetSelector} from '../../../../../store/state/incidents-report-filter/selectors'
import {displayIncidentWidgetOnDashboard} from '../../../../../store/state/incidents-report-filter/action-creators'
import {useDispatch} from 'react-redux'
interface IncidentsReportOptionsProps {
    setOpenIncidentsWidgetDropDown: Dispatch<SetStateAction<boolean>>
}
export function IncidentsReportOptions({
    setOpenIncidentsWidgetDropDown,
}: IncidentsReportOptionsProps): JSX.Element {
    const incidentsWidgetOnDashboard = useTypedSelector(selectedIncidentWidgetSelector)
    const dispatch = useDispatch()

    function onChangeWrapper(event: React.ChangeEvent<HTMLSelectElement>): void {
        event.preventDefault()
        dispatch(displayIncidentWidgetOnDashboard(event.target.value as IncidentWidgetType))
        setOpenIncidentsWidgetDropDown(false)
    }
    return (
        <Styled.FilterComponentWrapper>
            <Styled.FilterComponent>
                <Styled.Wrapper id="incidents-widgets-options-wrapper">
                    <Styled.Label>Selected widget</Styled.Label>
                    <Styled.Input>
                        <Styled.Select
                            onChange={onChangeWrapper}
                            value={incidentsWidgetOnDashboard}
                            id="incidents-widgets_select"
                        >
                            <option
                                key="default-option"
                                value={IncidentWidgetType.LATEST_INCIDENT}
                                id="incidents-widgets_default-option"
                            >
                                {getIncidentWidgetTypeDisplayName(
                                    IncidentWidgetType.LATEST_INCIDENT,
                                )}
                            </option>
                            {allIncidentWidgetsTypes?.map((value) => (
                                <option
                                    key={value}
                                    value={value}
                                    id={`incidents-widgets_${value}-option`}
                                >
                                    {getIncidentWidgetTypeDisplayName(value)}
                                </option>
                            ))}
                        </Styled.Select>
                    </Styled.Input>
                </Styled.Wrapper>
            </Styled.FilterComponent>
        </Styled.FilterComponentWrapper>
    )
}
const allIncidentWidgetsTypes: IncidentWidgetType[] = [
    IncidentWidgetType.CURRENT_STATUS,
    IncidentWidgetType.CURRENT_ASSIGNMENT,
    IncidentWidgetType.TYPES_INCIDENTS,
    IncidentWidgetType.SEVERITY_INCIDENTS,
    IncidentWidgetType.RAISED_INCIDENTS_TRENDS,
    IncidentWidgetType.INCIDENT_RESPONSE_PERFORMANCE,
    IncidentWidgetType.VESSELS_RESPONSE_PERFORMANCE,
]
