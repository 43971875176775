import {PropsWithChildren, useReducer} from 'react'
import {
    LocationInventoryPageContext,
    defaultLocationInventoryContextState,
} from './location-inventory-page-context'
import _ from 'lodash'
import {assetListReducer} from './state/asset-list/reducer'
import {AssetFormReducer} from './state/asset-form/reducer'
import {AssetFormOptionsReducer} from './state/asset-form-options/reducer'
import {PageReducer} from './state/page/reducer'
import {AssetUploadModalReducer} from './state/asset-upload-modal/reducer'

export function LocationInventoryPageProvider({children}: PropsWithChildren<object>) {
    const [assetListState, assetListDispatch] = useReducer(
        assetListReducer,
        _.cloneDeep(defaultLocationInventoryContextState.assetList.state),
    )

    const [assetFormState, assetFormDispatch] = useReducer(
        AssetFormReducer,
        _.cloneDeep(defaultLocationInventoryContextState.assetForm.state),
    )

    const [assetFormOptionsState, assetFormOptionsDispatch] = useReducer(
        AssetFormOptionsReducer,
        _.cloneDeep(defaultLocationInventoryContextState.assetFormOptions.state),
    )

    const [pageState, pageDispatch] = useReducer(
        PageReducer,
        _.cloneDeep(defaultLocationInventoryContextState.page.state),
    )

    const [assetUploadModalState, assetUploadModalDispatch] = useReducer(
        AssetUploadModalReducer,
        _.cloneDeep(defaultLocationInventoryContextState.assetUploadModal.state),
    )

    return (
        <LocationInventoryPageContext.Provider
            value={{
                assetList: {dispatch: assetListDispatch, state: assetListState},
                assetForm: {state: assetFormState, dispatch: assetFormDispatch},
                assetFormOptions: {
                    state: assetFormOptionsState,
                    dispatch: assetFormOptionsDispatch,
                },
                assetUploadModal: {
                    state: assetUploadModalState,
                    dispatch: assetUploadModalDispatch,
                },
                page: {
                    state: pageState,
                    dispatch: pageDispatch,
                },
            }}
        >
            {children}
        </LocationInventoryPageContext.Provider>
    )
}
