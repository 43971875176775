import ActionType from './action-type'
import * as Actions from './actions'
import {ThunkAction} from 'redux-thunk'
import {REST} from '../../../index'
import {Api} from '../../../api/Api'
import AppState from '../../types/app-state'
import {
    AuditEntry,
    AuditLogAuditType,
    AuditLogPageType,
    AuditLogSourceType,
    AuditVisibilityType,
    DownloadFileType,
} from './state'
import {GuidType} from '../../../values/generic-type-defintions'
import {SortColumnType} from '../../../pages/vessels-beta/contexts/types/sort-column'
import {
    TimestampFilterType,
    getTypeDisplayName,
} from '../../../pages/vessels-beta/contexts/types/timestamp-filter'
import {PossibleColumnsVesselBeta} from '../../../pages/vessels-beta/contexts/types/paged-vessels-beta-state'
import {NodeValue} from '../../../values/nodes/NodeData'

const AUDIT_ENDPOINT = '/audit/api/v1/audits'

const requestAuditLog = (): Actions.RequestAuditLogAction => ({
    type: ActionType.REQUEST_AUDIT_LOG,
})

const setAuditLog = (data: AuditEntry[]): Actions.ReceivedAuditLogAction => ({
    type: ActionType.RECEIVED_AUDIT_LOG,
    payload: data,
})

export function fetchAuditLog(userId: GuidType): ThunkAction<void, AppState, Api, Actions.Action> {
    return (dispatch) => {
        dispatch(requestAuditLog())

        REST.post(`${AUDIT_ENDPOINT}/find`, {
            userId: userId,
            pagination: {
                pageSize: 20,
                pageOffset: 0,
            },
            orderBy: {column: 'created_at', isAscending: false},
            visibility: AuditVisibilityType.ALL,
        }).then((response) => {
            dispatch(setAuditLog(response.data.data))
        })
    }
}

export function logPageAccess(
    currentPage: AuditLogPageType,
    auditType: AuditLogAuditType,
): ThunkAction<void, AppState, Api, Actions.Action> {
    return (_dispatch, getState) => {
        const currentUserId = getState().currentUser.currentUser.user
        REST.post(AUDIT_ENDPOINT, {
            type: auditType,
            message: `Viewed ${currentPage} page`,
            source: AuditLogSourceType.UI,
            userId: currentUserId,
        })
    }
}

export function logDetailsOpen(
    currentPage: AuditLogPageType,
    auditType: AuditLogAuditType,
    detailId: GuidType,
): ThunkAction<void, AppState, Api, Actions.Action> {
    return (_dispatch, getState) => {
        const currentUserId = getState().currentUser.currentUser.user
        REST.post(AUDIT_ENDPOINT, {
            type: auditType,
            message: `Opened ${detailId} on ${currentPage}`,
            source: AuditLogSourceType.UI,
            userId: currentUserId,
        })
    }
}

export function logAssetOverlayOpen(
    currentPage: AuditLogPageType,
    auditType: AuditLogAuditType,
    detailId: GuidType,
): ThunkAction<void, AppState, Api, Actions.Action> {
    return (_dispatch, getState) => {
        const currentUserId = getState().currentUser.currentUser.user
        REST.post(AUDIT_ENDPOINT, {
            type: auditType,
            message: `Opened asset overlay ${detailId} from ${currentPage}`,
            source: AuditLogSourceType.UI,
            userId: currentUserId,
        })
    }
}

export function logAssetOverlayTabClick(
    currentPage: AuditLogPageType,
    auditType: AuditLogAuditType,
    detailId: GuidType,
    tab: string,
): ThunkAction<void, AppState, Api, Actions.Action> {
    return (_dispatch, getState) => {
        const currentUserId = getState().currentUser.currentUser.user
        REST.post(AUDIT_ENDPOINT, {
            type: auditType,
            message: `Clicked ${tab} tab on asset overlay from ${currentPage} for ${detailId}`,
            source: AuditLogSourceType.UI,
            userId: currentUserId,
        })
    }
}

export function logVesselSettings(
    currentPage: AuditLogPageType,
    auditType: AuditLogAuditType,
    detailId: GuidType,
): ThunkAction<void, AppState, Api, Actions.Action> {
    return (_dispatch, getState) => {
        const currentUserId = getState().currentUser.currentUser.user
        REST.post(AUDIT_ENDPOINT, {
            type: auditType,
            message: `Opened Vessel Settings for ${detailId} on ${currentPage}`,
            source: AuditLogSourceType.UI,
            userId: currentUserId,
        })
    }
}

export function logInterlinksClick(
    targetPage: AuditLogPageType,
    auditType: AuditLogAuditType,
    detailId: GuidType,
): ThunkAction<void, AppState, Api, Actions.Action> {
    return (_dispatch, getState) => {
        const currentUserId = getState().currentUser.currentUser.user
        REST.post(AUDIT_ENDPOINT, {
            type: auditType,
            message: `Clicked interlink ${detailId} to ${targetPage} page`,
            source: AuditLogSourceType.UI,
            userId: currentUserId,
        })
    }
}

export function logSavedFilterUsage(
    currentPage: AuditLogPageType,
    auditType: AuditLogAuditType,
): ThunkAction<void, AppState, Api, Actions.Action> {
    return (_dispatch, getState) => {
        const currentUserId = getState().currentUser.currentUser.user
        REST.post(AUDIT_ENDPOINT, {
            type: auditType,
            message: `${auditType} on ${currentPage} page`,
            source: AuditLogSourceType.UI,
            userId: currentUserId,
        })
    }
}

export function logDownloadFile(
    currentPage: AuditLogPageType,
    detailId: GuidType,
    fileType: DownloadFileType,
): ThunkAction<void, AppState, Api, Actions.Action> {
    return (_dispatch, getState) => {
        const currentUserId = getState().currentUser.currentUser.user
        REST.post(AUDIT_ENDPOINT, {
            type: AuditLogAuditType.DOWNLOAD_FILE,
            message: `Download ${fileType} file for ${detailId} on ${currentPage}`,
            source: AuditLogSourceType.UI,
            userId: currentUserId,
        })
    }
}
export function logSortByColumn(
    newSortColumn: SortColumnType,
): ThunkAction<void, AppState, Api, Actions.Action> {
    return (_dispatch, getState) => {
        const currentUserId = getState().currentUser.currentUser.user
        REST.post(AUDIT_ENDPOINT, {
            type: AuditLogAuditType.SORT_BY_COLUMN,
            message: `Sort by ${newSortColumn.orderBy} in ${
                newSortColumn.orderAscending ? `ascending` : `descending`
            } direction on Vessels Beta Page`,
            source: AuditLogSourceType.UI,
            userId: currentUserId,
        })
    }
}
export function logAnalysisPeriod(
    analysisPeriod: TimestampFilterType,
    selectedColumns: PossibleColumnsVesselBeta[],
): ThunkAction<void, AppState, Api, Actions.Action> {
    return (_dispatch, getState) => {
        const currentUserId = getState().currentUser.currentUser.user
        REST.post(AUDIT_ENDPOINT, {
            type: AuditLogAuditType.SET_ANALYSIS_PERIOD,
            message: `Change analysis period to ${getTypeDisplayName(
                analysisPeriod,
            )} for ${selectedColumns.join(', ')}} on Vessels Beta Page`,
            source: AuditLogSourceType.UI,
            userId: currentUserId,
        })
    }
}

export function logAnalysisPeriodOnFilter(
    analysisPeriod: string,
    analysisType: string,
    currentPage: AuditLogPageType,
): ThunkAction<void, AppState, Api, Actions.Action> {
    return (_dispatch, getState) => {
        const currentUserId = getState().currentUser.currentUser.user
        REST.post(AUDIT_ENDPOINT, {
            type: AuditLogAuditType.SET_ANALYSIS_PERIOD,
            message: `Change analysis period to ${analysisPeriod} for ${analysisType} on ${currentPage} Page`,
            source: AuditLogSourceType.UI,
            userId: currentUserId,
        })
    }
}

export function logAnalysisTypeOnFilter(
    analysisType: string,
    currentPage: AuditLogPageType,
): ThunkAction<void, AppState, Api, Actions.Action> {
    return (_dispatch, getState) => {
        const currentUserId = getState().currentUser.currentUser.user
        REST.post(AUDIT_ENDPOINT, {
            type: AuditLogAuditType.SET_ANALYSIS_TYPE,
            message: `Change analysis type to ${analysisType} on ${currentPage} Page`,
            source: AuditLogSourceType.UI,
            userId: currentUserId,
        })
    }
}

export function logFilterByTypes(
    type: string,
    typeOption: string,
    typesToDisplay: boolean,
    currentPage: AuditLogPageType,
): ThunkAction<void, AppState, Api, Actions.Action> {
    return (_dispatch, getState) => {
        const currentUserId = getState().currentUser.currentUser.user
        REST.post(AUDIT_ENDPOINT, {
            type: AuditLogAuditType.SET_FILTER_BY_TYPE,
            message: `Set ${type}: ${typeOption} to be ${
                typesToDisplay ? `included` : `excluded`
            } from filter on ${currentPage} Page`,
            source: AuditLogSourceType.UI,
            userId: currentUserId,
        })
    }
}

export function logSelectedColumns(
    selectedColumn: PossibleColumnsVesselBeta,
    columnToDisplay: boolean,
): ThunkAction<void, AppState, Api, Actions.Action> {
    return (_dispatch, getState) => {
        const currentUserId = getState().currentUser.currentUser.user
        REST.post(AUDIT_ENDPOINT, {
            type: AuditLogAuditType.SET_COLUMN_TO_BE_DISPLAYED,
            message: `Set column: ${selectedColumn} to be ${
                columnToDisplay ? `displayed` : `removed`
            } on Vessels Beta Page`,
            source: AuditLogSourceType.UI,
            userId: currentUserId,
        })
    }
}
export function logInterlinksClickForVesselsBeta(
    targetPage: AuditLogPageType,
    detailId: GuidType,
    column: string,
): ThunkAction<void, AppState, Api, Actions.Action> {
    return (_dispatch, getState) => {
        const currentUserId = getState().currentUser.currentUser.user
        REST.post(AUDIT_ENDPOINT, {
            type: AuditLogAuditType.INTERLINK_CLICK,
            message: `Clicked interlink ${detailId} on ${column} column to ${targetPage} page`,
            source: AuditLogSourceType.UI,
            userId: currentUserId,
        })
    }
}
export function logChangeAssetValues(
    assetValue: NodeValue | undefined,
    hostName: string | null,
    detailId: string | null,
): ThunkAction<void, AppState, Api, Actions.Action> {
    return (_dispatch, getState) => {
        const currentUserId = getState().currentUser.currentUser.user
        let message = 'Change assets data:'

        if (assetValue !== undefined) {
            message += ` new assetValue: ${assetValue}`
        }

        if (hostName !== null) {
            message += ` new hostName: ${hostName}`
        }
        REST.post(AUDIT_ENDPOINT, {
            type: AuditLogAuditType.SET_CHANGE_ASSET_VALUES,
            message: `${message} for ${detailId}`,
            source: AuditLogSourceType.UI,
            userId: currentUserId,
        })
    }
}
export function logDownloadPolicyFile(
    detailId: GuidType,
    fileType: DownloadFileType,
    policyId: GuidType,
    currentPage: string,
): ThunkAction<void, AppState, Api, Actions.Action> {
    return (_dispatch, getState) => {
        const currentUserId = getState().currentUser.currentUser.user
        REST.post(AUDIT_ENDPOINT, {
            type: AuditLogAuditType.DOWNLOAD_FILE,
            message: `Download ${fileType} file: ${detailId} for ${currentPage} : ${policyId}`,
            source: AuditLogSourceType.UI,
            userId: currentUserId,
        })
    }
}
export function logButtonClickFor(
    eventClicked: string,
    currentPage: AuditLogPageType,
): ThunkAction<void, AppState, Api, Actions.Action> {
    return (_dispatch, getState) => {
        const currentUserId = getState().currentUser.currentUser.user
        REST.post(AUDIT_ENDPOINT, {
            type: AuditLogAuditType.BUTTON_CLICK,
            message: `Clicked button to ${eventClicked} on ${currentPage} page`,
            source: AuditLogSourceType.UI,
            userId: currentUserId,
        })
    }
}
