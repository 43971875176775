import {DataLoading} from '../../../../../components/data-loading/data-loading'
import {IncidentsResponsePerformanceWidget} from '../../../../incidents-report/components/incidents-response-performance/incidents-response-performance-widget'
import {usePagedIncidentsReport} from '../../../../incidents-report/contexts/use-paged-incidents-report'
import * as Styled from './widget-commonstyling.styled'

export function IncidentsPerformanceWidget(): JSX.Element {
    const {loadedIncidentsByAvgPerformance} = usePagedIncidentsReport()
    return (
        <Styled.StatusFilterContainer id="trends-section-trends-severity-container">
            <Styled.SectionText>INCIDENT RESPONSE PERFORMANCE</Styled.SectionText>
            {loadedIncidentsByAvgPerformance ? (
                <IncidentsResponsePerformanceWidget />
            ) : (
                <DataLoading />
            )}
        </Styled.StatusFilterContainer>
    )
}
