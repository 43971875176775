import {DataLoading} from '../../../../../components/data-loading/data-loading'
import {IncidentsTypesWidget} from '../../../../incidents-report/components/incidents-types-and-severities/incidents-types-widget'
import {usePagedIncidentsReport} from '../../../../incidents-report/contexts/use-paged-incidents-report'
import * as Styled from './widget-commonstyling.styled'

export function TypesIncidentsWidget(): JSX.Element {
    const {loadedIncidentsCounts} = usePagedIncidentsReport()
    return (
        <Styled.TypesFilterContainer id="status-section-status-count-container">
            <Styled.SectionText>TYPES OF INCIDENTS</Styled.SectionText>
            {loadedIncidentsCounts ? <IncidentsTypesWidget /> : <DataLoading />}
        </Styled.TypesFilterContainer>
    )
}
