import styled from 'styled-components'
import {Colors} from '../../../theme/colours'
import {spacing} from '../../../theme/spacing'

export const Table = styled.table`
    width: 100%;
    font-size: 14px;
`

interface CellProp {
    formColumn?: boolean
}

export const Cell = styled.td<CellProp>`
    padding: ${spacing(3)};
    /* Needed to make the dropdown display correctly.
     Otherwise it would get cutoff. 'hidden' is needed
     to make sure text is truncated when we are not editing. */
    overflow: ${(props) => (props.formColumn ? 'visible' : 'hidden')};
    text-overflow: ellipsis;
    white-space: nowrap;
`
export const Header = styled.th`
    padding: ${spacing(3)};
    border-bottom: 10px solid ${Colors.grey6};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 0;
`

export const Row = styled.tr`
    background-color: ${Colors.white};
    border-top: 8px solid ${Colors.grey6};
`
export const RowDetail = styled.tr`
    background-color: ${Colors.white};
    border-top: 1px solid ${Colors.grey1};
`

export const CellDiv = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`

export const ActionContent = styled.div`
    display: flex;
    gap: ${spacing(2)};
    justify-content: start;
    align-items: start;
`
