import {Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts'
import {CurrentTypesTooltip} from './current-incidents-count-tooltip'
import {getIncidentTypeIconFromFormattedNamesc} from '../../contexts/data-helper'
import {incidentTypesSelector} from '../../../../store/state/incident-types/selectors'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {usePagedIncidentsReport} from '../../contexts/use-paged-incidents-report'
import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import {narrowLayoutFn} from '../../incidents-report-page.styled'
import {IncidentWidgetType} from '../../contexts/types/incident-widget-type'
import {selectedIncidentWidgetSelector} from '../../../../store/state/incidents-report-filter/selectors'

export function IncidentsTypesWidget(): JSX.Element {
    const {width} = useDimensions()
    const incidentsWidgetOnDashboard = useTypedSelector(selectedIncidentWidgetSelector)

    const {totalNumberOfItemsByTypes} = usePagedIncidentsReport()
    const incidentTypes = useTypedSelector(incidentTypesSelector)
    const narrowWidth = narrowLayoutFn(width)
    const smallerHeight = incidentsWidgetOnDashboard === IncidentWidgetType.TYPES_INCIDENTS
    return (
        <ResponsiveContainer height={smallerHeight ? 200 : 300} minHeight={200}>
            <BarChart data={totalNumberOfItemsByTypes}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                    dataKey="displayName"
                    tick={(props) => <CustomXAxisWrapper {...props} narrowWidth={narrowWidth} />}
                />
                <YAxis />

                <Tooltip
                    content={
                        <CurrentTypesTooltip
                            data={totalNumberOfItemsByTypes}
                            incidentTypes={incidentTypes}
                        />
                    }
                />

                <Bar dataKey="value" fill="#C95D37" background={{fill: '#eee'}} />
            </BarChart>
        </ResponsiveContainer>
    )
}
interface CustomXAxisTickProps {
    x: number
    y: number
    payload: {
        value: string
    }
    narrowWidth: boolean
}
const CustomXAxisTick = ({x, y, payload, narrowWidth}: CustomXAxisTickProps) => {
    return (
        <image
            x={x - 5}
            y={y}
            href={getIncidentTypeIconFromFormattedNamesc(payload.value)}
            height={narrowWidth ? 10 : 20}
            width={narrowWidth ? 10 : 20}
        />
    )
}
const CustomXAxisWrapper = (props: {
    x: number
    y: number
    payload: {
        value: string
    }
    narrowWidth: boolean
}) => {
    const {x, y, payload, narrowWidth} = props

    if (!payload.value) {
        return (
            <text x={x} y={y} textAnchor="middle" fill="#666">
                NA
            </text>
        )
    }
    return <CustomXAxisTick x={x} y={y} payload={payload} narrowWidth={narrowWidth} />
}
