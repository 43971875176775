import {DataLoading} from '../../../../../components/data-loading/data-loading'
import {IncidentsSeveritiesWidget} from '../../../../incidents-report/components/incidents-types-and-severities/incidents-severities-widget'
import {usePagedIncidentsReport} from '../../../../incidents-report/contexts/use-paged-incidents-report'
import * as Styled from './widget-commonstyling.styled'

export function SeverityIncidentsWidget(): JSX.Element {
    const {loadedIncidentsCounts} = usePagedIncidentsReport()
    return (
        <Styled.StatusFilterContainer id="severity-section-severity-count-container">
            <Styled.SectionText>SEVERITY OF INCIDENTS</Styled.SectionText>
            {loadedIncidentsCounts ? <IncidentsSeveritiesWidget /> : <DataLoading />}
        </Styled.StatusFilterContainer>
    )
}
