// "uploading" and "upload error" are client side statuses,
// It exist only to be used to display different messages.
// in the UI, it never goes to the server or will come from the server.
export enum AssetBulkFileStatus {
    UPLOADING = 'uploading',
    UPLOAD_ERROR = 'upload error',
    UPLOADED = 'uploaded',
    CANCELLED = 'cancelled',
    PROCESSING = 'processing',
    REJECTED = 'rejected',
    COMPLETED = 'completed',
}
// Server response
export interface AssetBulkFile {
    id: string
    locationID: string
    status: AssetBulkFileStatus
    name: string
    checksum: string
    createdAt: string
    updatedAt: string
}
