import {IncidentWidgetType} from '../../../../incidents-report/contexts/types/incident-widget-type'

export function getIncidentWidgetTypeDisplayName(types: IncidentWidgetType): string {
    switch (types) {
        case IncidentWidgetType.CURRENT_STATUS:
            return 'Current status of raised incidents'
        case IncidentWidgetType.CURRENT_ASSIGNMENT:
            return 'Current assignment of new/open incidents'
        case IncidentWidgetType.TYPES_INCIDENTS:
            return 'Types of incidents raised'
        case IncidentWidgetType.SEVERITY_INCIDENTS:
            return 'Severity of incidents raised'
        case IncidentWidgetType.RAISED_INCIDENTS_TRENDS:
            return 'Raised incidents trends'
        case IncidentWidgetType.INCIDENT_RESPONSE_PERFORMANCE:
            return 'Incidents response performance'
        case IncidentWidgetType.VESSELS_RESPONSE_PERFORMANCE:
            return 'Vessels performance'
        case IncidentWidgetType.LATEST_INCIDENT:
        default:
            return 'Incidents'
    }
}
